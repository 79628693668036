import React from "react"

import { Box, Link, Typography } from "@material-ui/core"
import { Link as GatsbyLink } from "gatsby"
import { Helmet } from "react-helmet"

import MainShell from "../components/main-shell"
import ReturnForm from "../components/return-form/return-form-redux"

const ReturnPage = () => {
  return (
    <MainShell breadcrumbs={[{ label: `Retourbeleid` }]}>
      <Helmet>
        <title>Retourbeleid - Todocards.nl</title>

        <meta
          name="description"
          content="Tot 14 dagen nadat je jouw bestelling hebt ontvangen kan je deze kosteloos retour sturen. Wij zullen dan zo snel mogelijk het volledige aankoopbedrag (inclusief verzendkosten) terugstorten via de door jou gebruikte betaalmethode."
        />
      </Helmet>

      <Typography variant="h1" gutterBottom>
        Retourbeleid
      </Typography>

      <Typography paragraph={true}>
        Tot 14 dagen nadat je jouw bestelling hebt ontvangen kan je deze
        kosteloos retour sturen. Wij zullen dan zo snel mogelijk het volledige
        aankoopbedrag (inclusief verzendkosten) terugstorten via de door jou
        gebruikte betaalmethode.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Annuleren
      </Typography>

      <Typography paragraph={true}>
        Als jouw bestelling nog niet verzonden is, dan kan je deze annuleren.
        Neem dan zo snel mogelijk contact met ons op via het formulier onderaan
        deze pagina, hiermee voorkomen we dat het pakket onnodig heen en weer
        gestuurd wordt.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Bestelling nog niet ontvangen
      </Typography>

      <Typography paragraph={true}>
        Bestellingen die al verzonden zijn kunnen wij helaas niet meer
        annuleren. Wel kan je alvast het onderstaande formulier invullen, dan
        krijg je van ons een retourlabel waarmee je jouw bestelling direct terug
        kunt sturen.
      </Typography>

      <Typography paragraph={true}>
        Ben je toevallig thuis als jouw bestelling wordt bezorgd? Dan kan je het
        pakket weigeren, deze wordt dan automatisch retour gestuurd. Zou je het
        ons willen laten weten als je dit doet? Dan kunnen wij er rekening mee
        houden.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Retourvoorwaarden
      </Typography>

      <Typography paragraph={true}>
        Het retourneren van jouw bestelling is aan de volgende voorwaarden
        gebonden:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Jouw retouraanvraag is gedaan binnen 14 dagen na ontvangst van de
          bestelling en aan ons kenbaar gemaakt via onderstaand formulier of één
          van de opties op onze{" "}
          <Link component={GatsbyLink} underline="always" to="/contact">
            contactpagina
          </Link>
          .
        </Typography>

        <Typography paragraph={true}>
          2. Je bent zorgvuldig met het product omgegaan, hebt deze alleen
          geïnspecteerd zoals je dat ook in de winkel zou mogen doen en gebruikt
          in de mate die nodig is om de aard, de kenmerken en de werking van het
          product vast te stellen.
        </Typography>

        <Typography paragraph={true}>
          3. Het product wordt in zo origineel mogelijke staat en verpakking
          teruggestuurd.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Bestelling retourneren
      </Typography>

      <Typography paragraph={true}>
        Volg de onderstaande stappen om jouw bestelling retour te sturen:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Vul het formulier onderaan deze pagina in of neem contact met ons
          op via de{" "}
          <Link component={GatsbyLink} underline="always" to="/contact">
            contactpagina
          </Link>
          .
        </Typography>

        <Typography paragraph={true}>
          2. Wij zullen jou zo snel mogelijk per e-mail een retourlabel sturen
          en een overzicht van locaties waar je het pakket af kunt geven.
        </Typography>

        <Typography paragraph={true}>
          3. Maak het pakket goed dicht met tape en plak het retourlabel erop.
          Je hoeft verder niets in te vullen, aan de hand van het retourlabel
          kunnen wij zien dat het pakket van jou afkomstig is.
        </Typography>

        <Typography paragraph={true}>
          4. Lever het pakket in bij één van de afleverlocaties. Zodra wij het
          pakket in goede orde hebben ontvangen zullen wij zo snel mogelijk het
          volledige aankoopbedrag (inclusief verzendkosten) terugstorten via de
          door jou gebruikte betaalmethode.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Retourformulier
      </Typography>

      <Typography paragraph={true}>
        Neem contact met ons op via de{" "}
        <Link component={GatsbyLink} underline="always" to="/contact">
          contactpagina
        </Link>
        , of vul het onderstaande formulier in, om een retouraanvraag te doen.
      </Typography>

      <ReturnForm />
    </MainShell>
  )
}

export default ReturnPage
